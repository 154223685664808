import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const SuccessPage = () => (
  <Layout>
    <Seo 
        title="Sucess" 
    />
        <section className="bg-blue-dk">
            <div className="container">
                <div className="row">
                    <div className="hero-subpage">
                        <div className="hero-subpage-text">
                            <h1>Success</h1>
                            <p className="lead">We've received your contact form submission and will be in touch as soon as possible.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="last-section with-pad-top">
            <div className="container">
			    <div className="row terms">
                    <p className="lead">You might find the answers you are looking for in our <Link to="/faqs">FAQs</Link>. If not we'll answer all of your questions when we contact you.</p>
                    <p className="lead">Enabli is located in Sydney, Australia and we usually respond to enquiries between 9am - 6pm AEST (Monday - Friday).</p>
                </div>        
            </div>
        </section>

  </Layout>
)

export default SuccessPage